<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <!-- <form action="#" @submit.prevent="submit"> -->
        <form
          action="#"
          @submit.prevent="
            showModal({ type: 'submit', data: payload.persetujuan })
          "
        >
          <div class="form-body">
            <h3 class="card-title">Pencatatan Surat</h3>
            <hr />

            <div class="row p-t-20">
              <div class="col-md-4">
                <label>
                  Agenda Surat Masuk
                  <small style="color: red">*</small>
                </label>
                <div>
                  <Input
                    v-model="payload.agenda"
                    name="agenda"
                    disabled
                    required
                    type="text"
                  />
                </div>
              </div>
            </div>

            <div class="row p-t-20 mb-3">
              <div
                :class="[
                  'col-md-4',
                  { 'has-danger': errors.has('nomorsurat') },
                ]"
              >
                <label>
                  Nomor Surat
                  <small style="color: red">*</small>
                </label>
                <div class="input-group">
                  <!-- <Input class="form-control" v-model="payload.nomorsurat" name="nomorsurat" required type="text"/>  v-validate="'min:6|regex:^([a-zA-Z0-9]+)$'"-->
                  <input
                    v-model="payload.nomorsurat"
                    name="nomorsurat"
                    v-validate="'required'"
                    type="text"
                    class="form-control"
                    placeholder="Nomor Surat"
                    :class="[
                      'form-control',
                      { 'form-control-danger': errors.has('nomorsurat') },
                    ]"
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      @click="openModal"
                      type="button"
                    >
                      Add
                    </button>
                  </div>
                </div>
                <small
                  class="form-control-feedback"
                  v-show="errors.has('nomorsurat')"
                  >this field is required</small
                >
              </div>

              <div class="col-md-4">
                <label :class="['control-label']">
                  Sifat Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidSifatSurat }]">
                  <Multiselect
                    v-model="payload.sifat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Sifat Surat"
                    open-direction="bottom"
                    :options="dropdownSifatSurat.urgencyList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownSifatSurat.value"
                    :loading="dropdownSifatSurat.isLoadingSifatSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchSifatSurat"
                    @input="onChangeSifatSurat"
                    @search-change="sifatSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidSifatSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>

              <div class="col-md-4">
                <label>
                  Kecepatan Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidKecepatanSurat }]">
                  <Multiselect
                    v-model="payload.kecrat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Kecepatan Surat"
                    open-direction="bottom"
                    :options="dropdownKecepatanSurat.kecratList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKecepatanSurat.value"
                    :loading="dropdownKecepatanSurat.isLoadingKecepatanSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchKecepatanSurat"
                    @input="onChangeKecepatanSurat"
                    @search-change="kecepatanSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKecepatanSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <label>
                  Tanggal Surat
                  <small style="color: red">*</small>
                </label>
                <div>
                  <Input
                    v-model="payload.tanggal_surat"
                    name="tanggal_surat"
                    placeholder="YYYY-MM-DD"
                    required
                    type="date"
                  />
                  <!-- <Datepicker
                    wrapper-class="fullscreen-when-on-mobile"
                    input-class="form-control normal-background"
                    v-model="payload.tanggal_surat"
                    required
                    @input="ambilTanggal"
                  /> -->
                </div>
              </div>

              <div class="col-md-6">
                <label>
                  Tanggal Diterima
                  <small style="color: red">*</small>
                </label>
                <div>
                  <Input
                    v-model="payload.tanggal_diterima"
                    name="tanggal_diterima"
                    placeholder="YYYY-MM-DD"
                    required
                    type="date"
                  />
                  <!-- <Datepicker
                    wrapper-class="fullscreen-when-on-mobile"
                    input-class="form-control normal-background"
                    v-model="payload.tanggal_diterima"
                    required
                    :disabledDates="disabledDates"
                  /> -->
                </div>
              </div>
            </div>

            <div class="row p-t-20">
              <div class="col-md-4">
                <label>Jumlah Halaman</label>
                <div>
                  <Input
                    v-model="payload.pages"
                    name="pages"
                    :min="1"
                    type="number"
                    placeholder="Halaman"
                  />
                </div>
              </div>

              <div class="col-md-4">
                <label>Lampiran</label>
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <Input
                      v-model="payload.jumlah"
                      placeholder="Jumlah"
                      name="jumlah"
                      :min="0"
                      type="number"
                    />
                  </div>
                  <div class="col-md-6 m-l-5">
                    <!-- <div :class="['col-md-6 m-l-5',{ 'invalid': isInvalidLampiran }]"> -->
                    <Multiselect
                      v-model="payload.lampiran"
                      label="text"
                      track-by="value"
                      placeholder="Type to search"
                      open-direction="bottom"
                      :options="dropdownLampiran.lampiranList"
                      :multiple="false"
                      :value="dropdownLampiran.value"
                      :searchable="true"
                      :loading="dropdownLampiran.isLoadingJenisLampiran"
                      :internal-search="false"
                      :clear-on-select="true"
                      :close-on-select="true"
                      :options-limit="300"
                      :max-height="600"
                      :show-no-results="true"
                      :hide-selected="true"
                      :selectLabel="''"
                      @input="onChangeLampiran"
                      @close="onTouchLampiran"
                      @search-change="jenisLampiran"
                    >
                      <span slot="noResult">Oops! No User found.</span>
                    </Multiselect>
                    <!-- <label
                      class="typo__label form__label"
                      v-show="isInvalidLampiran"
                    >Must have at least one value</label> -->
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <label :class="['control-label']">
                  Jenis Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidJenisSurat }]">
                  <Multiselect
                    v-model="payload.jenis"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Jenis Surat"
                    open-direction="bottom"
                    :options="dropdownJenisSurat.categoryList"
                    :multiple="false"
                    :value="dropdownJenisSurat.value"
                    :searchable="true"
                    :loading="dropdownJenisSurat.isLoadingJenisSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeJenisSurat"
                    @close="onTouchJenisSurat"
                    @search-change="jenisSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidJenisSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label :class="['control-label']">
                  Klasifikasi
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidKlasifikasi }]">
                  <Multiselect
                    v-model="payload.klasifikasi"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Sifat Surat"
                    open-direction="bottom"
                    :options="dropdownKlasifikasi.klasifikasiList"
                    :multiple="false"
                    :searchable="true"
                    :value="dropdownKlasifikasi.value"
                    :loading="dropdownKlasifikasi.isLoadingKlasifikasi"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @close="onTouchKlasifikasi"
                    @input="onChangeKlasifikasi"
                    @search-change="klasifikasi"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKlasifikasi"
                    >Must have at least one value</label
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div
                :class="[
                  'col-md-12 m-t-10 m-b-10',
                  { 'has-danger': errors.has('perihal') },
                ]"
              >
                <label>
                  Perihal
                  <small style="color: red">*</small>
                </label>
                <div>
                  <textarea
                    v-model="payload.perihal"
                    name="perihal"
                    v-validate="'required'"
                    placeholder="Perihal"
                    :class="[
                      'form-control',
                      { 'form-control-danger': errors.has('perihal') },
                    ]"
                  ></textarea>
                  <small
                    class="form-control-feedback"
                    v-show="errors.has('perihal')"
                    >this field is required</small
                  >
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-4">
                <label>
                  Tipe Asal Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidTipeAsalSurat }]">
                  <Multiselect
                    v-model="payload.tas"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Tipe Asal Surat"
                    open-direction="bottom"
                    :options="dropdownTipeAsalSurat.tasList"
                    :multiple="false"
                    :value="dropdownTipeAsalSurat.value"
                    :searchable="true"
                    :loading="dropdownTipeAsalSurat.isLoadingTipeAsalSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeTipeAsalSurat"
                    @close="onTouchTipeAsalSurat"
                    @search-change="tipeAsalSurat"
                    @change="asalSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidTipeAsalSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  Asal Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidAsalSurat }]">
                  <Multiselect
                    v-model="payload.asrat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Asal Surat"
                    open-direction="bottom"
                    :options="dropdownAsalSurat.asratList"
                    :multiple="false"
                    :value="dropdownAsalSurat.value"
                    :searchable="true"
                    :disabled="payload.tas.length === 0"
                    :loading="dropdownAsalSurat.isLoadingAsalSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeAsalSurat"
                    @close="onTouchAsalSurat"
                    @search-change="asalSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidAsalSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col-md-4">
                <label>
                  Kode Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidKodeSurat }]">
                  <Multiselect
                    v-model="payload.kodrat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Jenis Surat"
                    open-direction="bottom"
                    :options="dropdownKodeSurat.kodratList"
                    :multiple="false"
                    :value="dropdownKodeSurat.value"
                    :searchable="true"
                    :loading="dropdownKodeSurat.isLoadingKodeSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeKodeSurat"
                    @close="onTouchKodeSurat"
                    @search-change="kodeSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKodeSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  Keterangan Surat
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidKeteranganSurat }]">
                  <Multiselect
                    v-model="payload.ketrat"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Keterangan Surat"
                    open-direction="bottom"
                    :options="dropdownKeteranganSurat.ketratList"
                    :multiple="false"
                    :value="dropdownKeteranganSurat.value"
                    :searchable="true"
                    :loading="dropdownKeteranganSurat.isLoadingKeteranganSurat"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeKeteranganSurat"
                    @close="onTouchKeteranganSurat"
                    @search-change="keteranganSurat"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidKeteranganSurat"
                    >Must have at least one value</label
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>
                  Redaksi
                  <small style="color: red">*</small>
                </label>
                <div :class="[{ invalid: isInvalidRedaksi }]">
                  <Multiselect
                    v-model="payload.redaksi"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Redaksi"
                    open-direction="bottom"
                    :options="dropdownRedaksi.redaksiList"
                    :multiple="false"
                    :value="dropdownRedaksi.value"
                    :searchable="true"
                    :loading="dropdownRedaksi.isLoadingRedaksi"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="true"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeRedaksi"
                    @close="onTouchRedaksi"
                    @search-change="redaksi"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <label
                    class="typo__label form__label"
                    v-show="isInvalidRedaksi"
                    >Must have at least one value</label
                  >
                </div>
              </div>

              <div class="col-md-8">
                <label>
                  Unit Kerja Penerima
                  <small style="color: red">*</small>
                </label>
                <!-- <div :class="[{ 'invalid': isInvalidUnitKerja }]"> -->
                <div>
                  <Multiselect
                    v-model="payload.unitkerja"
                    label="text"
                    track-by="value"
                    placeholder="Pilih Unit Kerja"
                    open-direction="bottom"
                    :options="dropdownUnitKerja.unitkerjaList"
                    :multiple="true"
                    :value="dropdownUnitKerja.value"
                    :searchable="true"
                    :loading="dropdownUnitKerja.isLoadingUnitKerja"
                    :internal-search="false"
                    :clear-on-select="true"
                    :close-on-select="false"
                    :options-limit="300"
                    :max-height="600"
                    :show-no-results="true"
                    :hide-selected="true"
                    @input="onChangeUnitKerja"
                    @close="onTouchUnitKerja"
                    @search-change="unitKerja"
                  >
                    <span slot="noResult">Oops! No Data found.</span>
                  </Multiselect>
                  <!-- <label
                    class="typo__label form__label"
                    v-show="isInvalidUnitKerja"
                  >Must have at least one value</label> -->
                </div>
              </div>
            </div>

            <hr class="m-t-40" />

            <div class="row">
              <div class="col-md-12 m-t-10 m-b-10">
                <label :class="['control-label']">Ringkasan Surat</label>
                <wysiwyg v-model="myHTML" />
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label :class="['control-label']"
                    >{{ $t("file") }} {{ $t("attachment") }}</label
                  >
                  <h6 v-if="!files.length">{{ $t("no_file") }}</h6>
                  <table v-else class="table table-stripped">
                    <tr>
                      <th>{{ $t("file") }}</th>
                      <th>{{ $t("action") }}</th>
                    </tr>
                    <tr v-for="file in files" v-bind:key="file.filename">
                      <td>
                        {{
                          file.filepath !== undefined
                            ? file.filename
                            : file.name
                        }}
                      </td>
                      <td>
                        <span v-if="file.filepath !== undefined">
                          <a
                            class="btn btn-success text-white"
                            @click="download(file.uuid, file.filename)"
                            ><i class="fa fa-download text-white"></i>
                          </a>
                          <a
                            class="btn btn-info text-white"
                            @click="prev(file.uuid)"
                            ><i class="fa fa-eye text-white"></i>
                          </a>
                        </span>
                        <i
                          ><b>{{ $t("not_saved") }} &nbsp;&nbsp;</b></i
                        >
                        <a
                          class="btn btn-danger text-white"
                          @click="removeAttachment(file.uuid)"
                        >
                          <i class="fa fa-trash-o text-white"></i>
                        </a>
                      </td>
                    </tr>
                  </table>
                  <Gallery :uploader="uploader" />
                </div>
              </div>
            </div>
          </div>

          <hr class="m-t-40" />

          <div class="form-actions">
            <button
              type="submit"
              class="btn btn-info"
              @click="setApproval(0)"
              title="Simpan"
            >
              <i class="fa fa-check"></i>
              {{ $t("save") }}
            </button>
            &nbsp;

            <!-- <button
              type="submit"
              class="btn btn-success"
              @click="setApproval(1)"
              title="Kirim"
            >
              <i class="mdi mdi-send"></i>
              Kirim
            </button> &nbsp; -->

            <button
              type="button"
              @click="showModal({ type: 'cancel' })"
              class="btn btn-inverse"
              title="Cancel"
            >
              Cancel
            </button>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>
    <Modal
      v-show="isModalVisible"
      @close="closeModal"
      @action="onDelete(deleteData)"
    />

    <hr />
    <div class="card">
      <h4>Petunjuk</h4>
      <table>
        <tr>
          <td>
            <span class="btn btn-info"
              ><i class="fa fa-check"></i> {{ $t("save") }}</span
            >
          </td>
          <td>Untuk simpan data surat</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <span class="btn btn-success"
              ><i class="mdi mdi-send"></i> Kirim</span
            >
          </td>
          <td>Untuk kirim data surat</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>
            <span class="btn btn-success"><i class="fa fa-download"></i></span>
          </td>
          <td>Untuk download file terlampir</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import Modal from "@/components/modal/modal.vue";
import store from "@/store";
import moment from "moment";
import momentz from "moment-timezone";

export default {
  components: {
    Modal,
    Input,
    Datepicker,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.pemberkasan;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    isInvalidSifatSurat() {
      return (
        this.dropdownSifatSurat.isTouched &&
        this.dropdownSifatSurat.value.length === 0
      );
    },
    isInvalidKecepatanSurat() {
      return (
        this.dropdownKecepatanSurat.isTouched &&
        this.dropdownKecepatanSurat.value.length === 0
      );
    },
    isInvalidLampiran() {
      return (
        this.dropdownLampiran.isTouched &&
        this.dropdownLampiran.value.length === 0
      );
    },
    isInvalidJenisSurat() {
      return (
        this.dropdownJenisSurat.isTouched &&
        this.dropdownJenisSurat.value.length === 0
      );
    },
    isInvalidKlasifikasi() {
      return (
        this.dropdownKlasifikasi.isTouched &&
        this.dropdownKlasifikasi.value.length === 0
      );
    },
    isInvalidTipeAsalSurat() {
      return (
        this.dropdownTipeAsalSurat.isTouched &&
        this.dropdownTipeAsalSurat.value.length === 0
      );
    },
    // isInvalidKategoriTujuan() {
    //   return (
    //     this.dropdownKategoriTujuan.isTouched &&
    //     this.dropdownKategoriTujuan.value.length === 0
    //   );
    // },
    // isInvalidKategoriAsalSurat() {
    //   return (
    //     this.dropdownKategoriAsalSurat.isTouched &&
    //     this.dropdownKategoriAsalSurat.value.length === 0
    //   );
    // },
    isInvalidAsalSurat() {
      return (
        this.dropdownAsalSurat.isTouched &&
        this.dropdownAsalSurat.value.length === 0
      );
    },
    isInvalidKodeSurat() {
      return (
        this.dropdownKodeSurat.isTouched &&
        this.dropdownKodeSurat.value.length === 0
      );
    },
    isInvalidKeteranganSurat() {
      return (
        this.dropdownKeteranganSurat.isTouched &&
        this.dropdownKeteranganSurat.value.length === 0
      );
    },
    isInvalidRedaksi() {
      return (
        this.dropdownRedaksi.isTouched &&
        this.dropdownRedaksi.value.length === 0
      );
    },
    isInvalidUnitKerja() {
      return (
        this.dropdownUnitKerja.isTouched &&
        this.dropdownUnitKerja.value.length === 0
      );
    },
  },
  data() {
    const uploader = this.fineUploader(this, "pemberkasan");

    return {
      uploader,
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      disabledDates: {
        to: new Date(Date.now()),
      },
      isModalVisible: false,
      payload: {
        agenda: "",
        nomorsurat: "",
        sifat: [],
        kecrat: [],
        tanggal_surat: "",
        tanggal_diterima: "",
        lampiran: { value: 2, text: "Lembar" },
        jenis: [],
        perihal: "",
        tas: [],
        asrat: [],
        kodrat: [],
        ketrat: [],
        redaksi: [],
        unitkerja: [],
        file: "",
        pages: 1,
        jumlah: 0,
        persetujuan: 0,
      },
      dropdownSifatSurat: {
        isTouched: false,
        urgencyList: [],
        value: [],
        isLoadingSifatSurat: false,
      },
      dropdownKecepatanSurat: {
        isTouched: false,
        kecratList: [],
        value: [],
        isLoadingKecepatanSurat: false,
      },
      dropdownLampiran: {
        isTouched: false,
        lampiranList: [],
        value: [],
        isLoadingJenisLampiran: false,
      },
      dropdownJenisSurat: {
        isTouched: false,
        categoryList: [],
        value: [],
        isLoadingJenisSurat: false,
      },
      dropdownKlasifikasi: {
        isTouched: false,
        klasifikasiList: [],
        value: [],
        isLoadingKlasifikasi: false,
      },
      dropdownTipeAsalSurat: {
        isTouched: false,
        tasList: [],
        value: [],
        isLoadingTipeAsalSurat: false,
      },
      dropdownAsalSurat: {
        isTouched: false,
        asratList: [],
        value: [],
        isLoadingAsalSurat: false,
      },
      dropdownKodeSurat: {
        isTouched: false,
        kodratList: [],
        value: [],
        isLoadingKodeSurat: false,
      },
      dropdownKeteranganSurat: {
        isTouched: false,
        ketratList: [],
        value: [],
        isLoadingKeteranganSurat: false,
      },
      dropdownRedaksi: {
        isTouched: false,
        redaksiList: [],
        value: [],
        isLoadingKeteranganSurat: false,
      },
      dropdownUnitKerja: {
        isTouched: false,
        unitkerjaList: [],
        value: [],
        isLoadingUnitKerja: false,
      },
      myHTML: "",
      myFile: [],
      files: [],
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
    const user = JSON.parse(localStorage.getItem("user"));
    if (!this.editMode) {
      this.payload.unitkerja = [{ value: user.unit_id, text: user.unit }];
    }

    console.log(this.payload.tanggal_surat);
  },

  watch: {
    $route: function (search) {
      if (this.editMode) {
        axios.get(`/document_in/${this.$route.params.id}`).then((response) => {
          const state = {
            loaded: true,
          };
          this.$store.commit("pemberkasan/STATE", state);
          this.edit(response.data.data);
          this.isModalVisible = false;
        });
      }
    },
  },

  methods: {
    removeAttachment(uuid) {
      let vm = this;
      let files = vm.uploader.methods.getUploads();
      files.map((element) => {
        if (element.uuid == uuid) {
          let folder;
          vm.files.map((ele) => {
            if (ele.uuid == uuid) {
              folder = ele.folder;
            }
          });
          vm.uploader.methods.setDeleteFileParams(
            { folder: folder },
            element.id
          );
          vm.uploader.methods.deleteFile(element.id);
        }
      });
      vm.files = vm.files.filter((element) => {
        return element.uuid != uuid;
      });
    },
    onClose() {
      this.isVisible = false;
    },
    async ambilTanggal() {
      this.disabledDates.to = this.payload.tanggal_surat;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "submit") {
        if (option.data == 0) {
          this.modalTitle = "Simpan Data?";
          this.modalContent = "<p>Apakah anda yakin ingin menyimpan surat?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        } else if (option.data == 1) {
          this.modalTitle = "Kirim Data?";
          this.modalContent = "<p>Apakah anda yakin ingin mengirim surat?</p>";
          this.isConfirmation = true;
          this.ActionChangeable = this.submit;
        }
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    setApproval(value) {
      if (this.disabledButton) {
        return;
      }
      this.disabledButton = true;
      setTimeout(() => (this.disabledButton = false), 1000);
      this.payload.persetujuan = value;
    },
    getAllReference() {
      var query = "";
      this.sifatSurat(query);
      this.kecepatanSurat(query);
      this.jenisLampiran(query);
      this.jenisSurat(query);
      this.klasifikasi(query);
      this.tipeAsalSurat(query);
      this.kodeSurat(query);
      this.keteranganSurat(query);
      this.redaksi(query);
      this.unitKerja(query);
    },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/document_in/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("pemberkasan/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("pemberkasan/onAdd");
          axios.get(`/document_in/new`).then((response) => {
            this.payload.agenda = response.data.data.agenda_number;
            this.payload.tanggal_surat = response.data.data.document_date;
            this.payload.tanggal_diterima = response.data.data.document_date;
          });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      this.payload = {
        unitkerja: data.receiver,
        agenda: data.agenda_number,
        tanggal_surat: data.tanggal_surat,
        tanggal_diterima: data.tanggal_diterima,
        sifat: data.document_sifat,
        jenis: data.document_jenis,
        perihal: data.subject,
        nama: data.document_name,
        nomorsurat: data.nomorsurat,
        pages: data.pages,
        myHTML: data.description,
        files: data.files,
        jumlah: data.jumlah,
        lampiran: data.document_lampiran,
        asrat: data.document_asrat,
        kodrat: data.document_kodrat,
        tas: data.document_tas,
        kecrat: data.document_kecrat,
        ketrat: data.document_ketrat,
        redaksi: data.document_redaksi,
      };
      this.files = data.files;
      this.dropdownSifatSurat.value = data.document_asrat;
      this.dropdownKecepatanSurat.value = data.document_kecrat;
      this.dropdownLampiran.value = data.document_lampiran;
      this.dropdownJenisSurat.value = data.document_jenis;
      this.dropdownTipeAsalSurat.value = data.document_tas;
      this.dropdownAsalSurat.value = data.document_asrat;
      this.dropdownKodeSurat.value = data.document_kodrat;
      this.dropdownKeteranganSurat.value = data.document_ketrat;
      this.dropdownRedaksi.value = data.document_redaksi;
      this.dropdownUnitKerja.value = data.receiver;
      this.myHTML = data.description;
    },
    goBack() {
      this.$router.push("/pemberkasan");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onDelete() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    submit() {
      let _this = this;
      if (!this.validateDate(this.payload.tanggal_surat)) {
        const message = {
          title: this.$t("error"),
          text: "Format tanggal surat harus sesuai format YYYY-MM-DD dan berupa tanggal yang valid",
          type: "error",
        };
        this.$store.commit("addNotification", message);
      }
      if (!this.validateDate(this.payload.tanggal_diterima)) {
        const message = {
          title: this.$t("error"),
          text: "Format tanggal diterima harus sesuai format YYYY-MM-DD dan berupa tanggal yang valid",
          type: "error",
        };
        this.$store.commit("addNotification", message);
      }
      if (
        _this.validateDate(_this.payload.tanggal_diterima) &&
        _this.validateDate(_this.payload.tanggal_surat)
      ) {
        console.log("Masuk");
        if (
          !_this.laterThan(
            _this.payload.tanggal_surat,
            _this.payload.tanggal_diterima
          )
        ) {
          const message = {
            title: _this.$t("error"),
            text: "Format tanggal diterima harus sesuai lebih besar dari tanggal surat",
            type: "error",
          };
          _this.$store.commit("addNotification", message);
        }
      }
      let that = _this;
      if (
        this.dropdownSifatSurat.value.length === 0 &&
        this.dropdownKecepatanSurat.value.length === 0 &&
        this.dropdownLampiran.value.length === 0 &&
        this.dropdownJenisSurat.value.length === 0 &&
        this.dropdownTipeAsalSurat.value.length === 0 &&
        this.dropdownAsalSurat.value.length === 0 &&
        this.dropdownKodeSurat.value.length === 0 &&
        this.dropdownKeteranganSurat.value.length === 0 &&
        this.dropdownRedaksi.value.length === 0 &&
        this.dropdownUnitKerja.value.length === 0
      ) {
        this.dropdownSifatSurat.isTouched = true;
        this.dropdownKecepatanSurat.isTouched = true;
        this.dropdownLampiran.isTouched = true;
        this.dropdownJenisSurat.isTouched = true;
        this.dropdownTipeAsalSurat.isTouched = true;
        this.dropdownAsalSurat.isTouched = true;
        this.dropdownKodeSurat.isTouched = true;
        this.dropdownKeteranganSurat.isTouched = true;
        this.dropdownRedaksi.isTouched = true;
        this.dropdownUnitKerja.isTouched = true;
        window.scrollTo(0, 0);
      }
      if (this.payload.file) {
        this.myFile = this.payload.file.split(",");
      }
      const payload = {
        agenda: this.payload.agenda,
        nomorsurat: this.payload.nomorsurat,
        document_kecrat: this.payload.kecrat,
        document_sifat: this.payload.sifat,
        tanggal_surat: this.payload.tanggal_surat,
        tanggal_diterima: this.payload.tanggal_diterima,
        document_lampiran: this.payload.lampiran,
        pages: this.payload.pages,
        document_jenis: this.payload.jenis,
        clasification_type: this.payload.klasifikasi,
        perihal: this.payload.perihal,
        document_tas: this.payload.tas,
        document_catto: this.payload.catto,
        document_asrat: this.payload.asrat,
        document_kodrat: this.payload.kodrat,
        document_ketrat: this.payload.ketrat,
        document_redaksi: this.payload.redaksi,
        receiver: this.payload.unitkerja,
        description: this.myHTML,
        files: this.files,
        jumlah: this.payload.jumlah,
        approval: this.payload.persetujuan,
        send: this.payload.persetujuan,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (
          success &&
          !this.isInvalidSifatSurat &&
          !this.isInvalidKecepatanSurat &&
          !this.isInvalidLampiran &&
          !this.isInvalidJenisSurat &&
          !this.isInvalidKlasifikasi &&
          !this.isInvalidTipeAsalSurat &&
          !this.isInvalidAsalSurat &&
          !this.isInvalidKeteranganSurat &&
          !this.isInvalidRedaksi &&
          !this.isInvalidUnitKerja &&
          this.validateDate(this.payload.tanggal_diterima) &&
          this.validateDate(this.payload.tanggal_surat) &&
          this.laterThan(
            this.payload.tanggal_surat,
            this.payload.tanggal_diterima
          )
        ) {
          if (!that.files.length) {
            const message = {
              title: "Error",
              text: "File harus di isi",
              type: "error",
            };
            that.$store.commit("addNotification", message);
            that.isVisible = false;
            return;
          }
          const state = {
            loaded: false,
          };
          this.$store.commit("pemberkasan/STATE", state);
          if (this.editMode) {
            this.$store
              .dispatch("pemberkasan/submitEdit", {
                data,
                id: this.$route.params.id,
              })
              .then((result) => {
                const state = {
                  loaded: true,
                };
                this.$store.commit("pemberkasan/STATE", state);
                this.showModal({
                  type: "information",
                  document_number: result.data.data.document_number,
                });
                this.isVisible = true;
              })
              .catch((err) => {
                const state = {
                  loaded: true,
                };
                this.$store.commit("pemberkasan/STATE", state);
                this.isVisible = false;
              });
          } else {
            this.$store.dispatch("pemberkasan/submitAdd", data);
          }
        } else {
          this.isVisible = false;
          window.scrollTo(0, 0);
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
    asyncFind(query) {
      this.dropdownTujuan.isLoadingUserFind = true;
      axios
        .post(`users/findUserTo`, { search: query })
        .then((res) => {
          this.dropdownTujuan.userList = res.data.data;
          this.dropdownTujuan.isLoadingUserFind = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    sifatSurat(query) {
      this.dropdownSifatSurat.isLoadingSifatSurat = true;
      axios
        .get(`document_urgencies/list?s=${query}`)
        .then((res) => {
          this.dropdownSifatSurat.urgencyList = res.data.items;
          this.dropdownSifatSurat.isLoadingSifatSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    kecepatanSurat(query) {
      this.dropdownKecepatanSurat.isLoadingKecepatanSurat = true;
      axios
        .get(`document_speeds/list?s=${query}`)
        .then((res) => {
          this.dropdownKecepatanSurat.kecratList = res.data.items;
          this.dropdownKecepatanSurat.isLoadingKecepatanSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    jenisLampiran(query) {
      this.dropdownLampiran.isLoadingJenisLampiran = true;
      axios
        .get(`attachment_types/list?s=${query}`)
        .then((res) => {
          this.dropdownLampiran.lampiranList = res.data.items;
          this.dropdownLampiran.isLoadingJenisLampiran = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    jenisSurat(query) {
      this.dropdownJenisSurat.isLoadingJenisSurat = true;
      axios
        .get(`document_categories/list?s=${query}`)
        .then((res) => {
          this.dropdownJenisSurat.categoryList = res.data.items;
          this.dropdownJenisSurat.isLoadingJenisSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    klasifikasi(query) {
      this.dropdownKlasifikasi.isLoadingKlasifikasi = true;
      axios
        .get(`clasificationdoc/list?s=${query}`)
        .then((res) => {
          this.dropdownKlasifikasi.klasifikasiList = res.data.items;
          this.dropdownKlasifikasi.isLoadingKlasifikasi = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    tipeAsalSurat(query) {
      this.dropdownTipeAsalSurat.isLoadingTipeAsalSurat = true;
      axios
        .get(`typeSurat/list?s=${query}`)
        .then((res) => {
          this.dropdownTipeAsalSurat.tasList = res.data.items;
          this.dropdownTipeAsalSurat.isLoadingTipeAsalSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    asalSurat(query) {
      const mv = this;
      mv.dropdownAsalSurat.isLoadingAsalSurat = true;
      axios
        .get(
          `units/listByLevel?s=${query ? query : ""}&type_code=${
            mv.payload.tas.value
          }`
        )
        .then((res) => {
          mv.dropdownAsalSurat.asratList = res.data.items;
          mv.dropdownAsalSurat.isLoadingAsalSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            mv.$store.dispatch("auth/logout");
          }
        });
    },
    kodeSurat(query) {
      this.dropdownKodeSurat.isLoadingKodeSurat = true;
      axios
        .get(`mail_code/list?s=${query}`)
        .then((res) => {
          this.dropdownKodeSurat.kodratList = res.data.items;
          this.dropdownKodeSurat.isLoadingKodeSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    keteranganSurat(query) {
      this.dropdownKeteranganSurat.isLoadingKeteranganSurat = true;
      axios
        .get(`description_mail/list?s=${query}`)
        .then((res) => {
          this.dropdownKeteranganSurat.ketratList = res.data.items;
          this.dropdownKeteranganSurat.isLoadingKeteranganSurat = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    redaksi(query) {
      this.dropdownRedaksi.isLoadingRedaksi = true;
      axios
        .get(`redaksi/list?s=${query}`)
        .then((res) => {
          this.dropdownRedaksi.redaksiList = res.data.items;
          this.dropdownRedaksi.isLoadingRedaksi = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    unitKerja(query) {
      this.dropdownUnitKerja.isLoadingUnitKerja = true;
      axios
        .get(`units/listIn2?s=${query}`)
        .then((res) => {
          this.dropdownUnitKerja.unitkerjaList = res.data.items;
          this.dropdownUnitKerja.isLoadingUnitKerja = false;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            this.$store.dispatch("auth/logout");
          }
        });
    },
    onTouchSifatSurat() {
      this.dropdownSifatSurat.isTouched = true;
    },
    onTouchKecepatanSurat() {
      this.dropdownKecepatanSurat.isTouched = true;
    },
    onTouchLampiran() {
      this.dropdownLampiran.isTouched = true;
    },
    onTouchJenisSurat() {
      this.dropdownJenisSurat.isTouched = true;
    },
    onTouchKlasifikasi() {
      this.dropdownKlasifikasi.isTouched = true;
    },
    onTouchTipeAsalSurat() {
      this.dropdownTipeAsalSurat.isTouched = true;
    },
    onTouchKategoriTujuan() {
      this.dropdownKategoriTujuan.isTouched = true;
    },
    onTouchKategoriAsalSurat() {
      this.dropdownKategoriAsalSurat.isTouched = true;
    },
    onTouchAsalSurat() {
      this.dropdownAsalSurat.isTouched = true;
    },
    onTouchKodeSurat() {
      this.dropdownKodeSurat.isTouched = true;
    },
    onTouchKeteranganSurat() {
      this.dropdownKeteranganSurat.isTouched = true;
    },
    onTouchRedaksi() {
      this.dropdownRedaksi.isTouched = true;
    },
    onTouchUnitKerja() {
      this.dropdownUnitKerja.isTouched = true;
    },
    onChangeSifatSurat(value) {
      this.dropdownSifatSurat.value = value;
      if (value.value === 3) {
        this.payload.ketrat = { value: 2, text: "Tertutup" };
      } else {
        this.payload.ketrat = [];
      }
    },
    download(filepath, filename) {
      axios
        .post("document_in/assets/single", { filepath: filepath })
        .then((res) => {
          const linkSource = res.data;
          const downloadLink = document.createElement("a");
          const fileName = filename;

          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    prev(string) {
      let routeData = this.$router.resolve({
        name: "preview.index",
        params: {
          document_type: "document_in",
          id: this.$route.params.id,
          file: string,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onChangeKecepatanSurat(value) {
      this.dropdownKecepatanSurat.value = value;
    },
    onChangeLampiran(value) {
      this.dropdownLampiran.value = value;
    },
    onChangeJenisSurat(value) {
      this.dropdownJenisSurat.value = value;
    },
    onChangeKlasifikasi(value) {
      this.dropdownKlasifikasi.value = value;
    },
    onChangeTipeAsalSurat(value) {
      this.dropdownTipeAsalSurat.value = value;
      this.asalSurat();
    },
    onChangeAsalSurat(value) {
      this.dropdownAsalSurat.value = value;
    },
    onChangeKategoriAsalSurat(value) {
      this.dropdownKategoriAsalSurat.value = value;
    },
    onChangeKodeSurat(value) {
      this.dropdownKodeSurat.value = value;
    },
    onChangeKeteranganSurat(value) {
      this.dropdownKeteranganSurat.value = value;
    },
    onChangeRedaksi(value) {
      this.dropdownRedaksi.value = value;
    },
    onChangeUnitKerja(value) {
      this.dropdownUnitKerja.value = value;
    },
    nomorDokumen(value) {
      this.payload.nomor_dokumen = value.document_number;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
